import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

function BCSTBenefits() {
  const { waterfall, bcst0, bcst1 } = useStaticQuery(graphql`
    query {
      waterfall: file(relativePath: { eq: "waterfall.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bcst0: file(relativePath: { eq: "bcst-2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bcst1: file(relativePath: { eq: "bcst-3.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className="px-8 md:px-16 lg:max-w-screen-lg mx-auto">
      <h1 className="my-8 md:my-12 lg:my-16 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider text-center">
        Benefits of Biodynamic Craniosacral Therapy (BCST)
      </h1>

      <Img
        className="my-6 md:my-12"
        fluid={waterfall.childImageSharp.fluid}
        alt="flowers and waterfall"
      />

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
          Nature may heal and cheer and give strength to the body and soul alike
        </blockquote>
        <cite className="block mt-4 text-xs font-bold text-right uppercase">
          - John Muir
        </cite>
      </div>

      <div className="my-12">
        <p className="mt-8">
          BCST is a gentle therapy due to its non-invasive, non-manipulative nature. It is a therapy that focuses on the health in your system and facilitating the body to self repair. A foundation of BCST is that health is always present no matter the ailment. If the focus has been on illness, trauma or pain, a connection with a sense of health can be lost. BCST can help you reconnect to the health and vitality within.
        </p>

        <p className="mt-8">
          After a series of treatments many clients notice an improvement in their symptoms such as:
        </p>

        <ul className="list-disc ml-8 mt-4">
          <li className="">
            A reduction in pain
          </li>
          <li className="">
            Improved sleep and digestion
          </li>
          <li className="">
            Feeling calmer and more relaxed
          </li>
          <li className="">
            Coping better with stressful situations
          </li>
          <li className="">
            Improved body awareness and function
          </li>
          <li className="">
            Feeling more connected and aware
          </li>
          <li className="">
            A renewed sense of direction and purpose
          </li>
          <li className="">
            Greater vitality and sense of wellbeing
          </li>
        </ul>
      </div>

      <div className="mt-12 mb-12 clear-left">
        <Img
          className="my-6 md:my-12"
          fluid={bcst1.childImageSharp.fluid}
          alt="BCST in practice"
        />

        <h4 className="mt-8 mb-4 md:mt-12 md:mb-4 lg:mt-16 lg:mb-4 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider">
          Acknowledging Trauma
        </h4>

        <p className="mt-4">
          Many conditions have their origins in physical or emotional trauma. Trauma is any experience that overwhelms your system’s ability to cope, which causes the old parts of your brain to to become stuck in a fight or flight or freeze state. If your physiology is stuck, you are stuck in repeating life-or-death protective reflexes. Over time this can contribute to many different symptoms. The good news is that the physiology of trauma is much simpler then the psychology; there is a way to heal trauma in a bottom-up approach where we do not need to understand or remember all the overwhelming things that happened.
        </p>

        <p className="mt-4">
          BCST works particularly well with the physiology of trauma, gently facilitating resolution through nervous system regulation. The intention of BCST is to renegotiate trauma in a contained, slowly paced way so that the client can feel safe and build their capacity to be with difficult sensations and feelings. This allows their body to process the protective reflexes that are working way too hard and return to a state of homeostasis. In turn the client begins to experience ease, calm, joy and a regained sense of self.
        </p>
      </div>

      <div className="my-12">
        <Img
          className="my-6 md:my-12"
          fluid={bcst0.childImageSharp.fluid}
          alt="BCST in practice"
        />

        <h4 className="mt-8 mb-4 md:mt-12 md:mb-6 lg:mt-16 lg:mb-8 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider">
          Conditions that Respond Favourably to BSCT
        </h4>

        <ul className="list-disc ml-8 mt-4">
          <li className="">
            Allergies/Asthma
          </li>
          <li className="">
            Anxiety
          </li>
          <li className="">
            Arthritis
          </li>
          <li className="">
            Autism
          </li>
          <li className="">
            Back Pain
          </li>
          <li className="">
            Birth Trauma
          </li>
          <li className="">
            Breast-feeding latching challenges
          </li>
          <li className="">
            Cerebral Palsy
          </li>
          <li className="">
            Chronic Fatigue
          </li>
          <li className="">
            Colic
          </li>
          <li className="">
            Concussion
          </li>
          <li className="">
            Dental/jaw trauma
          </li>
          <li className="">
            Depression
          </li>
          <li className="">
            Digestive issues
          </li>
          <li className="">
            Emotional overwhelm
          </li>
          <li className="">
            Exhaustion
          </li>
          <li className="">
            Fertility challenges
          </li>
          <li className="">
            Headaches/Migranes
          </li>
          <li className="">
            Hormonal issues
          </li>
          <li className="">
            Hyperactivity
          </li>
          <li className="">
            High blood pressure
          </li>
          <li className="">
            Insomnia/sleep challenges
          </li>
          <li className="">
            Joint disorders
          </li>
          <li className="">
            Low immunity
          </li>
          <li className="">
            Menstrual pain
          </li>
          <li className="">
            Morning sickness
          </li>
          <li className="">
            Muscular pain
          </li>
          <li className="">
            Neuralgia
          </li>
          <li className="">
            Plagiocephaly
          </li>
          <li className="">
            Premature birth
          </li>
          <li className="">
            PTSD
          </li>
          <li className="">
            Recovery/rehabilitation
          </li>
          <li className="">
            Reflux
          </li>
          <li className="">
            Sciatica
          </li>
          <li className="">
            Sinusitis
          </li>
          <li className="">
            Sprains/strains
          </li>
          <li className="">
            Stress
          </li>
          <li className="">
            Stroke
          </li>
          <li className="">
            Tongue-tie
          </li>
          <li className="">
            Torticollis
          </li>
          <li className="">
            TMJ
          </li>
          <li className="">
            Vestibular issues
          </li>
          <li className="">
            Visual issues
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BCSTBenefits;
